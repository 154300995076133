import React from 'react';
import { useData } from '../../utils/context';
import { Users, MessageSquare } from 'lucide-react';

const Dashboard: React.FC = () => {
  const { employees, reviews } = useData();

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Дэшбоард</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded shadow-md">
          <div className="flex items-center mb-4">
            <Users size={24} className="mr-2 text-blue-500" />
            <h2 className="text-xl font-semibold">Сотрудники</h2>
          </div>
          <p className="text-3xl font-bold">{employees.length}</p>
          <p className="text-gray-500 mt-2">Всего сотрудников в системе</p>
        </div>
        <div className="bg-white p-6 rounded shadow-md">
          <div className="flex items-center mb-4">
            <MessageSquare size={24} className="mr-2 text-green-500" />
            <h2 className="text-xl font-semibold">Отзывы</h2>
          </div>
          <p className="text-3xl font-bold">{reviews.length}</p>
          <p className="text-gray-500 mt-2">Всего отзывов в системе</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;