import './App.css';
import Home from './pages/index';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Navbar from './widgets/navbar';
import Footer from './widgets/footer';
import Countries from './pages/countries';
import { createContext, useState } from 'react';
import CountriesJson from './utils/countries.json';
import Modal from './entity/modal';
import ModalContent from './widgets/modal';
import Country from './pages/countries/[id]';
import { Country as CountryType } from './utils/home';
import Menu from './widgets/menu';
import { Element } from 'react-scroll';
import Dashboard from './pages/admin';
import EmployeesPage from './pages/admin/team';
import ReviewsPage from './pages/admin/review';
import { DataProvider } from './utils/context';
import Sidebar from './widgets/sidebar';
import LoginPage from './pages/admin/login';
import ProtectedRoute from './utils/protected';
import { AuthProvider } from './utils/authcontext';

export const ModalContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => { },
});

export const MenuContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => { },
});

export const CountriesContext = createContext<CountryType[]>(CountriesJson.countries);

const AdminLayout = () => {
  return (
    <AuthProvider>
      <DataProvider>
        <div className="flex h-screen bg-gray-100">
          <Sidebar />
          <div className="flex-1 overflow-auto">
            <Outlet />
          </div>
        </div>
      </DataProvider>
    </AuthProvider>
  )
}


const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <ModalContext.Provider value={{ isOpen, setIsOpen }}>
      <MenuContext.Provider value={{ isOpen: isMenuOpen, setIsOpen: setIsMenuOpen }}>
        <CountriesContext.Provider value={CountriesJson.countries as CountryType[]}> 
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalContent />
          </Modal>
          <Menu onClose={() => setIsMenuOpen(false)} />
          <Navbar />
          <Outlet />
          <Footer />
        </CountriesContext.Provider>
      </MenuContext.Provider>
    </ModalContext.Provider>
  )
}

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/countries/:id" element={<Country />} />
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="/admin/login" element={<LoginPage />} />
          <Route path="/admin" element={(
            <ProtectedRoute>
              <Dashboard /> 
            </ProtectedRoute>
          )} />
          <Route path="/admin/employees" element={(
            <ProtectedRoute>
              <EmployeesPage />
            </ProtectedRoute>
          )} />
          <Route path="/admin/reviews" element={(
            <ProtectedRoute>
              <ReviewsPage />
            </ProtectedRoute>
          )} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
