import { fetchReviews } from "../../../features/reviews";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import quotes from "../../../assets/home/reviews/quote.svg"
import Slider from "react-slick";
import { Button } from "../../../entity/button-native";
import { motion } from "framer-motion";
import ButtonSlider from "../../../entity/button-slider";
import { CountriesContext } from "../../../App";

export default function Reviews() {
    const [data, setData] = useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const countries = useContext(CountriesContext);
    const leftSliderRef = useRef<Slider>(null);
    const centralSliderRef = useRef<Slider>(null);
    const rightSliderRef = useRef<Slider>(null);
    const rightSliderRef2 = useRef<Slider>(null);
    const settings = {
        dots: false,
        infinite: false,
        fade: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        initialSlide: currentIndex,
        pauseOnFocus: false,
        onSwipe: (data: any) => {
            console.log("swipe", data);
        }
    }

    const handleLeft = () => {
        if (currentIndex === 0) {
            return;
        }
        leftSliderRef.current?.slickPrev();
        centralSliderRef.current?.slickPrev();
        rightSliderRef.current?.slickPrev();
        rightSliderRef2.current?.slickPrev();
    }

    const handleRight = () => {
        if (currentIndex === data.length - 1) {
            return;
        }
        leftSliderRef.current?.slickNext();
        centralSliderRef.current?.slickNext();
        rightSliderRef.current?.slickNext();
        rightSliderRef2.current?.slickNext();
    }

    useEffect(() => {
        fetchReviews().then((data) => {
            setData(data);
        })
    }, [])
    return (<div className="w-full pt-[31px] lg:pt-[77px] pb-[31px] lg:pb-[133px]">
        <div className="container">
            <h2 className="text-[28px] md:text-[32px] lg:text-[40px] font-semibold leading-[120%] text-center lg:text-left text-black">
                Отзывы Наших Туристов
            </h2>
            <div className="mt-[50px] w-full flex flex-col items-center md:flex-row">
                <div className="w-[340px] md:w-[351px] lg:w-[387px] aspect-[1.09631728045] overflow-hidden">
                    <Slider {...settings} ref={leftSliderRef} beforeChange={(current, next) => {
                        setCurrentIndex(next);
                    }} className="w-full h-full">
                        {data.map((item) => (
                            <div key={item.id}>
                                <div className="max-w-full aspect-[1.09631728045] flex justify-start items-end rounded-[25px] lg:rounded-[50px] overflow-hidden" style={{
                                    background: `url(${process.env.PUBLIC_URL + countries[Math.floor(Math.random() * countries.length)].bg})`
                                }}>
                                <div className="w-full flex items-center gap-2 lg:px-2 px-5 lg:py-4 py-5 text-[18px] font-normal leading-[120%] text-white">
                                    <img src={item.avatar} alt={item.name} className="w-[46px] h-[46px] rounded-full object-cover mr-2" />
                                    -
                                    {item.name}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="flex flex-col lg:flex-row w-[340px] md:w-[374px] lg:w-[813px] md:px-5 lg:px-8">
                    <div className="w-full md:w-[374px] lg:w-[607px] lg:px-8">
                        <div className="pt-3 md:pt-0 lg:pt-11 md:pb-12 lg:pb-3">
                            <img src={quotes} alt="quote" width={28} height={28}/>
                        </div>
                        <div className="text-center md:text-left">
                            <Slider {...settings} ref={centralSliderRef}>
                            {data.map((item) => (
                                <div key={item.id}>
                                    <div className="text-[18px] font-normal leading-[120%] text-black">
                                        {item.review}
                                    </div>
                                    <div className="flex md:hidden justify-center lg:justify-normal lg:flex mt-4 lg:mt-11 gap-8 text-[14px] font-normal leading-[120%] text-black">
                                        <span>-{item.name}</span><span className="text-[#BABABA]">Постоянный клиент</span>
                                    </div>
                                </div>
                            ))}
                            </Slider>
                        </div>
                        <div className="mt-9 hidden lg:block">
                            <div className="flex justify-between items-end">
                                <Button>
                                    Оставить заявку
                                </Button>
                                <span>
                                    {currentIndex + 1}/{data.length}
                                </span>
                            </div>
                            <div className="mt-8 w-full h-1 bg-[#EDEDED]">
                                <motion.div animate={{transform: `translateX(${currentIndex * 100}%)`}} transition={{duration: 0.5}} className="bg-[#B7B7B7] h-full" style={{width: `${100 / data.length}%`}}/>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[374px] lg:w-[206px] mt-12 md:mt-[38px] lg:mt-0 flex justify-between items-center md:justify-between md:items-end lg:block">
                        <div className="w-[83px] md:hidden md:w-[103px] lg:w-full aspect-[1.06735751295]">
                            <Slider {...settings} ref={rightSliderRef2} beforeChange={(current, next) => {
                                setCurrentIndex(next);
                            }} className="">
                                {data.map((item) => (
                                    <div key={item.id}>
                                        <div className="w-[83px] md:w-[103px] lg:w-full aspect-[1.06735751295] lg:bg-cover bg-center rounded-[15px] md:rounded-[25px] lg:rounded-[30px] overflow-hidden" style={{
                                            background: `url(${process.env.PUBLIC_URL + countries[Math.floor(Math.random() * countries.length)].bg})`
                                        }}/>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className="w-full flex gap-4 justify-center md:justify-start lg:justify-center lg:mt-[91px] lg:mb-5">
                            <ButtonSlider onClick={handleLeft} direction="left" />
                            <ButtonSlider onClick={handleRight} direction="right" />
                        </div>
                        <div className="w-[83px] md:w-[103px] lg:w-full aspect-[1.06735751295]">
                            <Slider {...settings} ref={rightSliderRef} beforeChange={(current, next) => {
                                setCurrentIndex(next);
                            }} className="">
                                {data.map((item) => (
                                    <div key={item.id}>
                                        <div className="w-[83px] md:w-[103px] lg:w-full aspect-[1.06735751295] lg:bg-cover bg-center rounded-[15px] md:rounded-[25px] lg:rounded-[30px] overflow-hidden" style={{
                                            background: `url(${process.env.PUBLIC_URL + countries[Math.floor(Math.random() * countries.length)].bg})`
                                        }}/>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:hidden justify-center items-center gap-10 mt-12">
                <div className="w-[250px] md:w-[453px] h-1 bg-[#EDEDED] rounded relative">
                    <motion.div animate={{left: `${currentIndex * 100 / (data.length)}%`}} transition={{duration: 0.5}} className="bg-[#B7B7B7] h-full absolute top-0 left-0" style={{width: `${100 / data.length}%`}}/>
                </div>
                <Button>
                    Оставить заявку
                </Button>
            </div>
        </div>
    </div>)
}
