import React, { useState } from 'react';
import { Review } from '../../../utils/types';
import { useData } from '../../../utils/context';
import ReviewForm from '../../../widgets/review';
import { Edit, Trash2, Plus } from 'lucide-react';

const ReviewsPage: React.FC = () => {
  const { reviews, addReview, updateReview, deleteReview } = useData();
  const [showForm, setShowForm] = useState(false);
  const [currentReview, setCurrentReview] = useState<Review | undefined>(undefined);

  const handleEdit = (review: Review) => {
    setCurrentReview(review);
    setShowForm(true);
  };

  const handleAdd = () => {
    setCurrentReview(undefined);
    setShowForm(true);
  };

  const handleSubmit = (review: Review | Omit<Review, 'id'>) => {
    if ('id' in review) {
      updateReview(review);
    } else {
      addReview(review);
    }
    setShowForm(false);
  };

  const handleCancel = () => {
    setShowForm(false);
    setCurrentReview(undefined);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Отзывы</h1>
        <button
          onClick={handleAdd}
          className="px-4 py-2 bg-blue-500 text-white rounded flex items-center hover:bg-blue-600"
        >
          <Plus size={18} className="mr-2" />
          Добавить отзыв
        </button>
      </div>

      {showForm && (
        <div className="mb-6">
          <ReviewForm
            review={currentReview}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      )}

      <div className="bg-white rounded shadow-md overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Аватар</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Имя</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Отзыв</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Действия</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {reviews.map((review) => (
              <tr key={review.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{review.id}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <img src={review.avatar} alt={review.name} className="h-10 w-10 rounded-full" />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{review.name}</td>
                <td className="px-6 py-4 text-sm text-gray-500 truncate max-w-xs">{review.review}</td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleEdit(review)}
                    className="text-blue-600 hover:text-blue-900 mr-3"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => deleteReview(review.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewsPage;
