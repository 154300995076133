import { CountriesContext } from "../../../App";
import { useContext } from "react";
import { useParams } from "react-router-dom";

export default function Attractions() {
    const countries = useContext(CountriesContext);
    const { id } = useParams();

    const country = countries.find((_, index) => index === parseInt(id as string));

    return (
        <div className="w-full mt-10">
            <div className="container px-[10px] md:px-[75px] text-center lg:text-left">
                {country?.attractions.map((attraction) => (
                    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-[57px] first:mt-0">
                        <img src={process.env.PUBLIC_URL + attraction.image} alt={attraction.name} className="block w-full h-full aspect-square lg:w-[597px] lg:h-[328px] lg:aspect-auto object-cover rounded-[30px]"/>
                        <div className="w-full">
                            <h2 className="text-[20px] md:text-[26px] lg:text-[30px] font-semibold text-[#151515]">
                                {attraction.name}
                            </h2>
                            <p className="text-[16px] lg:text-[18px] lg:mt-7 font-normal text-[#151515]">
                                {attraction.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
