import React, { useState, useEffect } from 'react';
import { Employee } from '../../utils/types';

interface EmployeeFormProps {
  employee?: Employee;
  onSubmit: (employee: Omit<Employee, 'id'> | Employee) => void;
  onCancel: () => void;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ employee, onSubmit, onCancel }) => {
  const [name, setName] = useState('');
  const [job, setJob] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
    if (employee) {
      setName(employee.name);
      setJob(employee.job);
      setImage(employee.image);
    }
  }, [employee]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (employee) {
      onSubmit({ id: employee.id, name, job, image });
    } else {
      onSubmit({ name, job, image });
    }
    resetForm();
  };

  const resetForm = () => {
    setName('');
    setJob('');
    setImage('');
  };

  return (
    <div className="bg-white p-6 rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">{employee ? 'Edit Employee' : 'Add New Employee'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Job</label>
          <input
            type="text"
            value={job}
            onChange={(e) => setJob(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Image URL</label>
          <input
            type="text"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="/api/placeholder/100/100"
            required
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {employee ? 'Update' : 'Add'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;