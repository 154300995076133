import search from '../../assets/countries/search.svg';
import { Button } from '../../entity/button-native';
import './styles.css';
import { useState, useContext } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { CountriesContext } from '../../App';
export default function Countries() {
    const directionList = useContext(CountriesContext);
    const [selectedCountry, setSelectedCountry] = useState<number>(0);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const handleClick = (n: number) => {
        if (!isMobile) {
            setSelectedCountry(n);
        } else {
            navigate(`/countries/${n}`);
        }
    }
    
    return (
    <div className="w-full">
        <div className='w-full h-[105px] md:hidden bg-black' />
        <div className="container">
            <h1 className='hidden md:block text-[24px] md:text-[32px] lg:text-[48px] font-semibold md:mb-[6px] lg:mb-[23px] md:mt-[26px] lg:mt-[44px]'>
                Наши Направления
            </h1>
            <div className="p-[10px] rounded-[30px] md:rounded-[18px] lg:rounded-[30px] mt-[15px] md:mt-0" style={{background: 'linear-gradient(112.15deg, #DCC26B -4.59%, rgba(234, 220, 154, 0.85) 10.88%, #BC973C 24.33%, rgba(234, 220, 154, 0.85) 36.49%, #BC973C 46.93%, rgba(234, 220, 154, 0.85) 59.31%, #BC973C 80.98%), #B08C36'}}>
                <div className="flex bg-white rounded-[25px] overflow-hidden md:rounded-[12px] lg:rounded-[20px] p-[15px] md:p-[6px] lg:p-[10px] h-full gap-[10px]">
                    <div className="w-full md:w-[30.583333333%]">
                        <div className="w-full h-[45px] md:h-[33px] lg:h-[55px] bg-[#F3ECE0] md:px-1 md:py-[3px] lg:py-[5px] lg:px-[10px] md:rounded-[6px] lg:rounded-[10px]">
                            <div className="relative w-full h-full md:rounded-[18px] lg:rounded-[30px] bg-white">
                                <div className="absolute left-[10px] top-[50%] translate-y-[-50%] block md:hidden lg:block">
                                    <img src={search} alt="search" />
                                </div>
                                <div className="w-full h-full text-center">
                                    <input type="text" className="w-full h-full rounded-[30px] md:rounded-[18px] md:text-[9.55px] lg:rounded-[30px] lg:text-[16px] bg-white text-center border border-[#EDEDED] md:border-none" placeholder="Поиск Направлений" />
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-2 md:mt-[6.45px] lg:mt-[21px] h-full md:h-[276px] lg:h-[430px] overflow-y-scroll" style={{direction: 'rtl'}}>
                            <div className='flex flex-col gap-[10px] text-[18px] px-2'>
                                {directionList.map((item, index) => (
                                    <CountryCard key={index} name={item.name} image={item.icon} delay={index * 0.1} onClick={() => handleClick(index)}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-[68.956521739%] overflow-hidden hidden md:block">
                        <AnimatePresence mode='popLayout'>
                            <motion.div 
                                key={selectedCountry}
                                initial={{opacity: 0, x: 50}}
                                animate={{opacity: 1, x: 0}}
                                transition={{duration: 0.3, ease: 'linear'}}
                                className='w-full md:h-[172px] lg:h-[286px] relative bg-cover rounded-[10px] bg-center' 
                                style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 26.57%, rgba(0, 0, 0, 0.8) 123.91%),url(${process.env.PUBLIC_URL + directionList[selectedCountry].bg}) no-repeat center center`}}
                            >
                                <h1 className='absolute bottom-[21px] w-full leading-[120%] text-center text-[37px] md:text-[34px] lg:text-[74px] font-semibold text-white'>
                                    {directionList[selectedCountry].name}
                                </h1>
                                <button 
                                    className='absolute top-[11px] right-[8px] bg-white rounded-[10px] border border-[#E1E1E1] overflow-hidden'
                                    onClick={() => navigate(`/countries/${selectedCountry}`)}
                                >
                                    <div className='relative group md:px-3 lg:px-[35px] md:py-1 lg:py-[10px]'>
                                        <div className='w-0 group-hover:w-full h-full bg-black transition-all duration-300 absolute inset-0' />
                                        <span className='relative z-10 group-hover:text-white transition-all duration-300 md:text-[12px] lg:text-[15px]'>
                                            Подробнее
                                        </span>
                                    </div>
                                </button>
                            </motion.div>
                            <div className='w-full flex gap-[10px] pt-[10px]'>
                                <motion.div
                                    key={selectedCountry}
                                    initial={{opacity: 0, x: 50}}
                                    animate={{opacity: 1, x: 0}}
                                    transition={{duration: 0.3, ease: 'linear', delay: 0.1}} 
                                    className='w-full md:h-[135px] lg:h-[226px] bg-white md:text-[14px] lg:text-[18px] rounded-[10px] border border-[#D5D5D5] overflow-y-scroll p-[10px]' style={{direction: 'rtl'}}>
                                    <div style={{direction: 'ltr'}}>
                                        {directionList[selectedCountry].description}
                                    </div>
                                </motion.div>
                                <motion.div
                                    key={selectedCountry * 2 + 1}
                                    initial={{opacity: 0, x: 50}}
                                    animate={{opacity: 1, x: 0}}
                                    transition={{duration: 0.3, ease: 'linear', delay: 0.2}}
                                    className='w-full md:h-[135px] lg:h-[226px] bg-white md:text-[14px] lg:text-[18px] rounded-[10px] border border-[#D5D5D5] overflow-y-scroll p-[10px]' style={{direction: 'rtl'}}>
                                    <div style={{direction: 'ltr'}}>
                                        <h2 className='text-[20px] font-semibold'>
                                            {directionList[selectedCountry].attractions[0].name}
                                        </h2>
                                        <p className='text-[18px]'>
                                            {directionList[selectedCountry].attractions[0].description}
                                        </p>
                                    </div>
                                </motion.div>
                            </div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            <div className='flex gap-8 justify-center md:justify-start items-center mt-[30px] mb-[60px]'>
                <Button>
                    Оставить заявку
                </Button>
                <Button type='secondary' onClick={() => navigate('/')}>
                    <span className='inline-block px-10 text-[#111111]'>
                        Назад
                    </span>
                </Button>
            </div>
        </div>
    </div>
    )
}


interface CountryCardProps {
    name: string;
    image: string;
    delay: number;
    onClick: () => void;
}

const CountryCard = ({name, image, delay, onClick}: CountryCardProps) => {
    return (
        <motion.div
            initial={{opacity: 0, x: '120%'}}
            animate={{opacity: 1, x: 0}}
            transition={{delay, duration: 0.1, ease: 'linear'}}
            className="bg-[#ECECEC] relative w-full h-[45px] md:h-[27px] lg:h-[45px] p-[2px] bg-white rounded-[30px] border border-[#EDEDED] cursor-pointer hover:bg-[#F3ECE0] transition-all duration-300" onClick={onClick}>
            <div className='w-[41px] h-[41px] md:w-[24px] md:h-[24px] lg:w-[41px] lg:h-[41px] rounded-full absolute left-[2px] md:left-[1px] lg:left-[2px] top-[2px] md:top-[1px] lg:top-[2px] bg-cover' style={{backgroundImage: `url(${process.env.PUBLIC_URL + image})`}}/>
            <div className="w-full h-full flex items-center justify-center whitespace-nowrap text-[18px] md:text-[14px] lg:text-[18px]" style={{direction: 'ltr'}}>
                <span>{name.slice(0, 17) + (name.length > 17 ? '...' : '')}</span>
            </div>
        </motion.div>
    )
}
