import { useEffect, useState, useRef } from "react";

interface ButtonProps {
  onSwipeComplete: () => void;
}

export default function Button({ onSwipeComplete }: ButtonProps) {
  const [isActive, setIsActive] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const buttonRef = useRef(null);

  // Calculate fill percentage based on swipe distance
  const getFillPercentage = () => {
    if (!isDragging) return isActive ? 100 : 0;
    //@ts-ignore
    const buttonWidth = buttonRef.current?.offsetWidth || 0;
    const dragDistance = Math.max(0, currentX - startX);
    const percentage = Math.min(100, (dragDistance / buttonWidth) * 100);
    
    return percentage;
  };

  const handleTouchStart = (e: any) => {
    setStartX(e.touches[0].clientX);
    setCurrentX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e: any) => {
    if (isDragging) {
      setCurrentX(e.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    //@ts-ignore
    const buttonWidth = buttonRef.current?.offsetWidth || 0;
    const dragDistance = Math.max(0, currentX - startX);
    const percentage = (dragDistance / buttonWidth) * 100;
    
    if (percentage > 50) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  // Mouse events for desktop testing
  const handleMouseDown = (e: any) => {
    setStartX(e.clientX);
    setCurrentX(e.clientX);
    setIsDragging(true);
  };

  const handleMouseMove = (e: any) => {
    if (isDragging) {
      setCurrentX(e.clientX);
    }
  };

  const handleMouseUp = () => {
    handleTouchEnd();
  };

  // Add and clean up global mouse event listeners
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  // Reset button after activation
  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        setIsActive(false);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      if (typeof onSwipeComplete === 'function') {
        onSwipeComplete();
      }
      
      const timer = setTimeout(() => {
        setIsActive(false);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [isActive, ]);

  const fillPercentage = getFillPercentage();

  return <>
    <button 
        ref={buttonRef} 
        className="group p-[2px] bg-[rgba(217,217,217,0.29)] rounded-[19.24px] border-[0.5px] border-white relative"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
    >
        <span 
            className={`absolute left-[2px] top-[2px] bottom-[2px] h-[calc(100%-4px)] transition-all duration-200 rounded-[17px] ${fillPercentage > 50 ? 'bg-black' : 'bg-white'}`}
            style={{
                width: `${fillPercentage}%`,
                left: 2,
                touchAction: 'none'
            }}
        />
        <span className={
            `py-[10px] px-[18px] inline-flex justify-center items-center text-[#151515] text-[11.12px] leading-[120%] font-medium 
            relative overflow-hidden- transition-all duration-100 ${fillPercentage > 50 ? 'text-white' : 'text-black'}`
        }>
          <span className='relative z-10'>
            Наши Туры
          </span>
        </span>
        <span className={`py-[10px] px-[18px] inline-flex bg-transparent justify-center items-center text-[#151515] text-[11.12px] leading-[120%] font-medium relative overflow-hidden ${fillPercentage > 50 ? 'text-white' : 'text-black'}`}>
          <span className='relative z-10'>
            Выберите свою путевку
          </span>
          <svg className='ml-[62px] relative z-10' width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.921 5.40008H0.608648C0.447225 5.40008 0.292413 5.46328 0.178269 5.57579C0.0641254 5.6883 0 5.84089 0 6C0 6.15911 0.0641254 6.3117 0.178269 6.42421C0.292413 6.53672 0.447225 6.59992 0.608648 6.59992H11.921L7.48151 10.9746C7.36722 11.0872 7.30301 11.24 7.30301 11.3993C7.30301 11.5586 7.36722 11.7114 7.48151 11.8241C7.59579 11.9367 7.7508 12 7.91243 12C8.07406 12 8.22906 11.9367 8.34335 11.8241L13.8212 6.42475C13.8779 6.36902 13.9228 6.30282 13.9535 6.22993C13.9842 6.15705 14 6.07891 14 6C14 5.92109 13.9842 5.84295 13.9535 5.77007C13.9228 5.69718 13.8779 5.63098 13.8212 5.57525L8.34335 0.175936C8.22906 0.0632858 8.07406 0 7.91243 0C7.7508 0 7.59579 0.0632858 7.48151 0.175936C7.36722 0.288585 7.30301 0.441371 7.30301 0.600682C7.30301 0.759993 7.36722 0.912779 7.48151 1.02543L11.921 5.40008Z" fill="#151515"/>
          </svg>
        </span>
      </button>
  </>
}
