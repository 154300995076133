import { useRef, useState } from "react";
import Slider from "react-slick";
import mask from "../../../../assets/home/team/mask.svg"
import "./index.css"
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import ButtonSlider from "../../../../entity/button-slider";
interface SliderProps {
    data: {
        id: number;
        job: string;
        name: string;
        image: string;
    }[]
}

export default function SliderComponent({ data }: SliderProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const SliderRef = useRef<Slider>(null);
    const isMobile = useMediaQuery({maxWidth: 768});

    const handleRightSwipe = () => {
        if (SliderRef.current) {
            if (currentIndex >= data.length) return;
            SliderRef.current.slickNext();
        }
    }

    const handleLeftSwipe = () => {
        if (SliderRef.current) {
            if (currentIndex < 0) return;
            SliderRef.current.slickPrev();
        }
    }

    return (
        <div className="mx-auto mt-12">
            <div className="max-w-[250px] md:max-w-[577px] mx-auto overflow-x-hidden">
                <Slider
                    ref={SliderRef}
                    dots={false}
                    infinite={false}
                    slidesToShow={3}
                    slidesToScroll={1}
                    variableWidth={true}
                    speed={500}
                    centerMode={false}
                    draggable={true}
                    initialSlide={0}
                    centerPadding="0px"
                    className="overflow-x-hidden"
                    beforeChange={(current, next) => {
                        setCurrentIndex(next);
                    }}
                    responsive={[
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]}
                >
                    {data.map((item) => (
                        <div key={item.id}>
                            <div className="w-[250px] h-[325px] md:w-[179px] md:h-[233px] rounded-[20px] overflow-hidden" style={{
                                backgroundImage: `url(${item.image})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}>
                                <div className="flex flex-col items-center justify-start pt-[6px]" style={{
                                    backgroundImage: `url(${mask}), linear-gradient(180deg, rgba(0, 0, 0, 0) 27.9%, rgba(0, 0, 0, 0.89) 135.19%)`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    width: "100%",
                                    height: "100%",
                                }}>
                                    <p className="text-white text-[13px] font-light text-center leading-[120%]">
                                        {item.job}
                                    </p>
                                    <h3 className="text-white text-[18px] font-normal text-center leading-[120%]">
                                        {item.name}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="max-w-[508px] h-1 bg-white rounded-[2px] mx-auto mt-12 relative">
                <motion.div animate={{
                    left: `${currentIndex * 100 / data.length * (isMobile ? 1 : 1)}%`
                }} style={{ width: `${100 / data.length * (isMobile? 1: 3)}%` }} className="absolute top-0 h-full bg-[#8CBDE9] rounded-[2px]" />
            </div>
            <div className="flex gap-[14px] justify-center mt-12">
                <ButtonSlider onClick={handleLeftSwipe} direction="left" />
                <ButtonSlider onClick={handleRightSwipe} direction="right" />
            </div>
        </div>
    )
}
