import React, { useState } from 'react';
import { Employee } from '../../../utils/types';
import { useData } from '../../../utils/context';
import EmployeeForm from '../../../widgets/team';
import { Edit, Trash2, Plus } from 'lucide-react';

const EmployeesPage: React.FC = () => {
  const { employees, addEmployee, updateEmployee, deleteEmployee } = useData();
  const [showForm, setShowForm] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<Employee | undefined>(undefined);

  const handleEdit = (employee: Employee) => {
    setCurrentEmployee(employee);
    setShowForm(true);
  };

  const handleAdd = () => {
    setCurrentEmployee(undefined);
    setShowForm(true);
  };

  const handleSubmit = (employee: Employee | Omit<Employee, 'id'>) => {
    if ('id' in employee) {
      updateEmployee(employee);
    } else {
      addEmployee(employee);
    }
    setShowForm(false);
  };

  const handleCancel = () => {
    setShowForm(false);
    setCurrentEmployee(undefined);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Сотрудники</h1>
        <button
          onClick={handleAdd}
          className="px-4 py-2 bg-blue-500 text-white rounded flex items-center hover:bg-blue-600"
        >
          <Plus size={18} className="mr-2" />
          Добавить сотрудника
        </button>
      </div>

      {showForm && (
        <div className="mb-6">
          <EmployeeForm
            employee={currentEmployee}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      )}

      <div className="bg-white rounded shadow-md overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Изображение</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Имя</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Должность</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Действия</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{employee.id}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <img src={employee.image} alt={employee.name} className="h-10 w-10 rounded-full" />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{employee.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{employee.job}</td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleEdit(employee)}
                    className="text-blue-600 hover:text-blue-900 mr-3"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => deleteEmployee(employee.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeesPage;