import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Employee, Review } from './types';
import * as api from './api';
import { useAuth } from './authcontext';

interface DataContextType {
  employees: Employee[];
  reviews: Review[];
  loading: {
    employees: boolean;
    reviews: boolean;
  };
  error: {
    employees: string | null;
    reviews: string | null;
  };
  addEmployee: (employee: Omit<Employee, 'id'>) => Promise<void>;
  updateEmployee: (employee: Employee) => Promise<void>;
  deleteEmployee: (id: number) => Promise<void>;
  addReview: (review: Omit<Review, 'id'>) => Promise<void>;
  updateReview: (review: Review) => Promise<void>;
  deleteReview: (id: number) => Promise<void>;
  refreshData: () => Promise<void>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState({
    employees: true,
    reviews: true,
  });
  const [error, setError] = useState({
    employees: null as string | null,
    reviews: null as string | null,
  });
  
  const { user } = useAuth();

  // Fetch data when component mounts and user changes
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    if (!user) return;
    
    await fetchEmployeesData();
    await fetchReviewsData();
  };

  const fetchEmployeesData = async () => {
    if (!user) return;
    
    setLoading(prev => ({ ...prev, employees: true }));
    setError(prev => ({ ...prev, employees: null }));
    try {
      const data = await api.fetchEmployees();
      setEmployees(data);
    } catch (err) {
      setError(prev => ({ ...prev, employees: err instanceof Error ? err.message : 'An unknown error occurred' }));
    } finally {
      setLoading(prev => ({ ...prev, employees: false }));
    }
  };

  const fetchReviewsData = async () => {
    if (!user) return;
    
    setLoading(prev => ({ ...prev, reviews: true }));
    setError(prev => ({ ...prev, reviews: null }));
    try {
      const data = await api.fetchReviews();
      setReviews(data);
    } catch (err) {
      setError(prev => ({ ...prev, reviews: err instanceof Error ? err.message : 'An unknown error occurred' }));
    } finally {
      setLoading(prev => ({ ...prev, reviews: false }));
    }
  };

  const refreshData = async () => {
    await fetchData();
  };

  // Employee operations
  const addEmployee = async (employee: Omit<Employee, 'id'>) => {
    try {
      await api.createEmployee(employee);
      await fetchEmployeesData();
    } catch (err) {
      setError(prev => ({ ...prev, employees: err instanceof Error ? err.message : 'Failed to add employee' }));
      throw err;
    }
  };

  const updateEmployee = async (employee: Employee) => {
    try {
      await api.updateEmployee(employee);
      await fetchEmployeesData();
    } catch (err) {
      setError(prev => ({ ...prev, employees: err instanceof Error ? err.message : 'Failed to update employee' }));
      throw err;
    }
  };

  const deleteEmployeeById = async (id: number) => {
    try {
      await api.deleteEmployee(id);
      await fetchEmployeesData();
    } catch (err) {
      setError(prev => ({ ...prev, employees: err instanceof Error ? err.message : 'Failed to delete employee' }));
      throw err;
    }
  };

  // Review operations
  const addReview = async (review: Omit<Review, 'id'>) => {
    try {
      await api.createReview(review);
      await fetchReviewsData();
    } catch (err) {
      setError(prev => ({ ...prev, reviews: err instanceof Error ? err.message : 'Failed to add review' }));
      throw err;
    }
  };

  const updateReview = async (review: Review) => {
    try {
      await api.updateReview(review);
      await fetchReviewsData();
    } catch (err) {
      setError(prev => ({ ...prev, reviews: err instanceof Error ? err.message : 'Failed to update review' }));
      throw err;
    }
  };

  const deleteReviewById = async (id: number) => {
    try {
      await api.deleteReview(id);
      await fetchReviewsData();
    } catch (err) {
      setError(prev => ({ ...prev, reviews: err instanceof Error ? err.message : 'Failed to delete review' }));
      throw err;
    }
  };

  return (
    <DataContext.Provider value={{
      employees,
      reviews,
      loading,
      error,
      addEmployee,
      updateEmployee,
      deleteEmployee: deleteEmployeeById,
      addReview,
      updateReview,
      deleteReview: deleteReviewById,
      refreshData
    }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};