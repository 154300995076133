import { User } from '../types';

export const login = async (username: string, password: string): Promise<User> => {
  const response = await fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Invalid username or password');
    }
    throw new Error('Login failed');
  }

  const data = await response.json();
  return { username, token: data.token };
};