import { motion } from 'framer-motion';
import './styles.css';
import ListItem from '../../../entity/list-item';
import { bookingList } from '../../../utils/home';
import booking from '../../../assets/home/booking/background.png';
import bg from './bg.png'
import bgsm from './bgsm.png'
import pathlg from './pathlg.svg'
import pathmd from './pathmd.svg'
import pathsm from './pathsm.svg'

export default function Booking() {
  const isDesktop = window.innerWidth > 1200
  const isMobile = window.innerWidth < 768

  if (isMobile) {
    return (<div className='mt-6'>
      <div className='container px-[10px]'>
        <div className='w-full h-[595px] bg-contain bg-no-repeat bg-center' style={{ backgroundImage: `url(${bgsm})` }}>
          <h2 className='lg:text-[48px] md:text-[32px] text-[24px] font-semibold leading-[100%] text-[#1C1C1C] text-center'>
            Этапы Бронирования
          </h2>
        </div>
        <div className='w-full h-[493.6px] bg-contain bg-no-repeat bg-center pt-10' style={{ backgroundImage: `url(${pathsm})` }}>
          <div className='flex flex-col items-center gap-[15.55px]'>
              {
                bookingList.map((item, index) => (
                  <ListItem key={index} icon={item.icon} text={item.text} />
                ))
              }
            </div>
        </div>
      </div>
    </div>)
  }
  
  return <div className='relative overflow-hidden lg:block md:mt-[63px] lg:mt-7' >
    <div className="container relative px-10 md:pt-[34px] lg:pt-[64.48px] md:bg-contain lg:bg-cover lg:bg-[top_center] bg-no-repeat" style={{ backgroundImage: `url(${isDesktop && pathlg})`, backgroundPositionY: isDesktop ? 'top' : 'bottom' }}>
      <div className='w-full relative z-10 bg-contain md:pb-[50px] lg:pb-2 bg-no-repeat bg-[bottom_center]' style={{ backgroundImage: `url(${bg})` }}>
        <div className='w-full flex justify-end'>
          <div className='lg:w-1/2 md:pr-[29px] lg:ml-[41px]'>
            <h2 className='lg:text-[48px] md:text-[32px] text-[24px] font-semibold leading-[100%] text-[#1C1C1C] text-center'>
              Этапы Бронирования
            </h2>
            <div className='flex flex-col md:gap-[14px] lg:gap-5 mt-[52px] lg:mt-[74px] pl-[26px]'>
              {
                bookingList.map((item, index) => (
                  <ListItem key={index} className={`${index % 2 !== (isDesktop ? 0 : 1) ? 'ml-12' : ''}`} icon={item.icon} text={item.text} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className='absolute top-0 left-0 w-full h-[411.19px] bg-cover bg-no-repeat bg-center lg:hidden z-0' style={{ backgroundImage: `url(${pathmd})` }} />
    </div>
  </div>
}
