//@ts-nocheck

import { Employee, Review } from '../types';

// Helper function to get auth header
const getAuthHeader = () => {
  const userJson = localStorage.getItem('user');
  if (!userJson) return {};
  
  const user = JSON.parse(userJson);
  return {
    'Authorization': `Bearer ${user.token}`
  };
};

// Employee API methods
export const fetchEmployees = async (): Promise<Employee[]> => {
  const response = await fetch('/api/team', {
    headers: {
      ...getAuthHeader()
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch employees');
  }
  return response.json();
};

export const createEmployee = async (employee: Omit<Employee, 'id'>): Promise<Employee> => {
  const response = await fetch('/api/team', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    },
    body: JSON.stringify(employee),
  });
  if (!response.ok) {
    throw new Error('Failed to create employee');
  }
  return response.json();
};

export const updateEmployee = async (employee: Employee): Promise<Employee> => {
  const response = await fetch(`/api/team`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    },
    body: JSON.stringify(employee),
  });
  if (!response.ok) {
    throw new Error('Failed to update employee');
  }
  return response.json();
};

export const deleteEmployee = async (id: number): Promise<void> => {
  const response = await fetch(`/api/team`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    },
    body: JSON.stringify({ id }),
  });
  if (!response.ok) {
    throw new Error('Failed to delete employee');
  }
};

// Review API methods
export const fetchReviews = async (): Promise<Review[]> => {
  const response = await fetch('/api/reviews', {
    headers: {
      ...getAuthHeader()
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch reviews');
  }
  return response.json();
};

export const createReview = async (review: Omit<Review, 'id'>): Promise<Review> => {
  const response = await fetch('/api/reviews', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    },
    body: JSON.stringify(review),
  });
  if (!response.ok) {
    throw new Error('Failed to create review');
  }
  return response.json();
};

export const updateReview = async (review: Review): Promise<Review> => {
  const response = await fetch(`/api/reviews`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    },
    body: JSON.stringify(review),
  });
  if (!response.ok) {
    throw new Error('Failed to update review');
  }
  return response.json();
};

export const deleteReview = async (id: number): Promise<void> => {
  const response = await fetch(`/api/reviews`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    },
    body: JSON.stringify({ id }),
  });
  if (!response.ok) {
    throw new Error('Failed to delete review');
  }
};