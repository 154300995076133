import logo from '../../assets/navbar/logo.svg';
import gmail from '../../assets/footer/mail.svg';
import telegram from '../../assets/footer/telegram.svg';
import instagram from '../../assets/footer/insta.svg';
import whatsapp from '../../assets/footer/whatsapp.svg';

export default function Footer() {
  return <div className='bg-[#1A1A1A] py-[14px] md:py-[40px] text-white'>
    <div className='container bg-black py-[21px] px-[14px] rounded-[20px]'>
      <div className='w-full'>
        <div className='flex gap-[10px] justify-start items-center'>
          <div className='w-[43.01px] h-[34.96px] bg-white' style={{ mask: `url(${logo}) no-repeat center` }}/>
          <span className='text-[17.29px] font-bold leading-[100%] text-white font-manrope uppercase'>
            Asiatique <br />
            Travel
          </span>
        </div>
        <span className='text-[17px] font-light font-manrope md:hidden'>
          Через степи к новым горизонтам.
        </span>
      </div>
      <div className='w-full h-[1px] bg-[#555555] mt-5 block md:hidden' />
      <div className='block md:hidden'>
        <div className='w-full mt-5'>
          <span className='text-[12px] font-light font-manrope'>
            Copyright © 2025 Asiatique Travel.
          </span><br />
          <span className='text-[12px] font-light font-manrope'>
            Все права защищены.
          </span>
        </div>
        <div className='grid grid-cols-2 gap-[17px] justify-start mt-5'>
          <Link href='/' icon={gmail}>Gmail</Link>
          <Link href='/' icon={telegram}>Telegram</Link>
          <Link href='/' icon={instagram}>Instagram</Link>
          <Link href='/' icon={whatsapp}>WhatsApp</Link>
        </div>
      </div>
      <div className='w-full mt-6 md:mt-3'>
        <div className='grid grid-cols-2 md:flex gap-[10px] justify-between items-start'>
          <div className='hidden md:block'>
            <span className='text-[17px] font-light font-manrope'>
              Через степи к новым горизонтам.
            </span>
            <div className='grid grid-cols-2 gap-[17px] justify-start items-start mt-5'>
              <Link href='/' icon={gmail}>Gmail</Link>
              <Link href='/' icon={telegram}>Telegram</Link>
              <Link href='/' icon={instagram}>Instagram</Link>
              <Link href='/' icon={whatsapp}>WhatsApp</Link>
            </div>
          </div>
          <div className=''>
            <span className='text-[20px] font-medium font-manrope'>
              Навигация
            </span>
            <ul className='block md:grid grid-cols-1 gap-1 justify-start mt-2 text-[12px] font-light font-manrope'>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Подбор тура</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Договор и оплата</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Направления</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Рассылки</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Контакты</a></li>
            </ul>
          </div>
          <div className=''>
            <span className='text-[20px] font-medium font-manrope'>
              Команда
            </span>
            <ul className='block md:grid grid-cols-1 gap-1 justify-start mt-2 text-[12px] font-light font-manrope'>
              <li className='inline-block mr-2'><a href='/' className='text-white'>О нас</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Вакансии</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Отдел заботы</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Отзывы</a></li>
            </ul>
          </div>
          <div className=''>
            <span className='text-[20px] font-medium font-manrope'>
              Дополнительно
            </span>
            <ul className='block md:grid grid-cols-1 gap-1 justify-start mt-2 text-[12px] font-light font-manrope'>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Рассрочка</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Политика</a></li>
              <li className='inline-block mr-2'><a href='/' className='text-white'>Горящие туры</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='w-full h-[1px] bg-[#555555] mt-5 hidden md:block' />
      <div className='w-full mt-5 hidden md:block'>
        <span className='text-[12px] font-light font-manrope'>
          Copyright © 2025 Asiatique Travel.
        </span><br />
        <span className='text-[12px] font-light font-manrope'>
          Все права защищены.
        </span>
      </div>
    </div>
  </div>;
}

const Link = ({ children, href, icon }: { children: React.ReactNode, href: string, icon: string }) => {
  return <a href={href} className='w-[117.7px] h-[28.17px] rounded-[13.2px] border border-[#555555] relative text-[11px] font-light font-manrope inline-flex gap-[10px] items-center justify-center'>
    <span className='text-white'>{children}</span>
    <div className='absolute top-[5.25px] left-[7px] w-[17.5px] h-[17.5px] bg-contain bg-no-repeat bg-center' style={{ background: `url(${icon}) no-repeat center` }}/>
  </a>
}
