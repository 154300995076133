
interface ListItemProps {
  icon: string;
  text: string;
  className?: string;
}

export default function ListItem({ icon, text, className }: ListItemProps) {
  return <div className={`w-[304px] h-[45.87px] md:w-[315px] lg:w-[392px] md:h-[35px] lg:h-[59px] p-[6px] bg-white rounded-[42px] border border-[rgba(195,195,195,0.32)] flex items-center justify-start ${className}`}>
    <div className="w-[65px] h-[36px] md:w-[49.6px] md:h-[27.75px] lg:w-[84px] lg:h-[47px] rounded-[36px] bg-gradient-to-b from-[#DCC26B] via-[rgba(234,220,154,0.85)] via-[47%] to-[#BC973C] flex items-center justify-center">
      <img src={icon} alt={text} className='w-[24.8px] h-[24.8px] md:w-[24.8px] md:h-[13.875px] lg:w-[42px] lg:h-[42px]' />
    </div>
    <p className="text-[#1C1C1C] md:text-[18px] text-[14px] font-medium text-center w-full">{text}</p>
  </div>
}