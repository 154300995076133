// import bg from '../../../assets/home/header/background.lg.jpg';
// import bgsc from '../../../assets/home/header/background-secondary.lg.jpg';
// import woman from '../../../assets/home/header/woman.lg.png';
import Slider from "react-slick";
import { motion } from "framer-motion";
import Button from '../../../entity/button';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { CountriesContext, ModalContext } from '../../../App';
import { useParams } from 'react-router-dom';

export default function Element() {
    const isDesktop = window.innerWidth > 1200;
    const isTablet = window.innerWidth <= 1200;

    const countries = useContext(CountriesContext);
    const { id } = useParams();

    const country = countries.find((_, index) => index === parseInt(id as string));
    const { setIsOpen } = useContext(ModalContext);

    if (isDesktop) {
        return (<div className='relative w-full'>
            <div className='container h-[calc(100vh-84px)] relative px-10 rounded-[30px]' style={{ backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0) 43.2%, #000000 132.35%), linear-gradient(180deg, rgba(0, 0, 0, 0) 49.9%, #000000 118.89%), url(${country?.bg})` }}>
                <div className='flex flex-col justify-end w-1/2 h-full pb-[54px] text-left items-start relative z-20 rounded-[30px]'>
                    <motion.h1
                        initial={{ x: -100, opacity: 0 }}
                        viewport={{ once: true }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.7 }}
                        className={`
                        text-white font-extrabold leading-[110%] text-[41px] text-center
                        md:leading-[101%] md:text-[48px] md:mt-8 md:text-left`
                        }
                    >
                        {country?.name}
                    </motion.h1>
                    <motion.p
                        initial={{ x: -100, opacity: 0 }}
                        viewport={{ once: true }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.7 }}
                        className={`text-white text-[18px] mt-4 leading-[101%] font-normal`}
                    >
                        {country?.description}
                    </motion.p>
                    <motion.div initial={{ x: -100, opacity: 0 }} viewport={{ once: true }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.7 }} className="flex gap-3 md:gap-2 lg:gap-[42px] mt-4 md:mt-8 justify-center md:justify-start">
                        <Button variant="primary" onClick={() => setIsOpen(true)}>
                            <span>Оставить заявку</span>
                        </Button>
                        <Link to='/#directions'>
                            <Button variant="secondary">
                                <span>Смотреть Направления</span>
                            </Button>
                        </Link>
                    </motion.div>
                </div>
                <div className='absolute bottom-0 right-0 w-[277px] pl-[10px] h-[346.17px] pt-[10px] rounded-tl-[20px] z-10 bg-white flex items-end justify-end'>
                    <img src={country?.bg2lg} alt={country?.name} className='w-full h-full object-cover' />
                </div>
            </div>
        </div>)
    }

    return <div className='relative w-full mt-2 lg:mt-0'>
        <div className="w-full h-[100vh] md:h-screen lg:h-[calc(100vh-84px)] relative px-[10px] md:px-5 lg:px-10">
            <Slider fade={true} autoplay={true} autoplaySpeed={10000} >
                <div className="w-full h-screen lg:h-[calc(100vh-84px)]">
                    <div className="relative z-50 w-full h-full flex bg-cover bg-no-repeat bg-center rounded-[30px]" style={{ backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0) 43.2%, #000000 132.35%), linear-gradient(180deg, rgba(0, 0, 0, 0) 49.9%, #000000 118.89%), url(${country?.bg})` }}>
                        <div className="container w-full h-full flex items-end justify-between px-5">
                            <div className="flex flex-col justify-end w-full h-full pb-[54px] md:text-center lg:text-left items-center lg:items-start relative z-50">
                                <motion.h1
                                    initial={{ x: -100, opacity: 0 }}
                                    viewport={{ once: true }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.7 }}
                                    className={`
                                text-white font-extrabold leading-[110%] text-[41px] text-center
                                md:leading-[101%] md:text-[48px] md:mt-8 md:text-left`
                                    }
                                >
                                    {country?.name}
                                </motion.h1>

                                <motion.p
                                    initial={{ x: -100, opacity: 0 }}
                                    viewport={{ once: true }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.7 }}
                                    className={`text-white text-[14px] leading-[101%] font-normal text-center`}
                                >
                                    {country?.description}
                                </motion.p>
                                <motion.div initial={{ x: -100, opacity: 0 }} viewport={{ once: true }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.7 }} className="flex gap-3 md:gap-2 lg:gap-[42px] mt-4 md:mt-8 justify-center md:justify-start">
                                    <Button variant="primary" onClick={() => setIsOpen(true)}>
                                        <span>Оставить заявку</span>
                                    </Button>
                                    <Link to='/#directions'>
                                        <Button variant="secondary">
                                            <span>Смотреть Направления</span>
                                        </Button>
                                    </Link>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-screen lg:h-[calc(100vh-84px)]">
                    <div className="relative z-20 w-full h-full flex bg-cover bg-no-repeat bg-center rounded-[30px]" style={{ backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0) 43.2%, #000000 132.35%), linear-gradient(180deg, rgba(0, 0, 0, 0) 49.9%, #000000 118.89%), url(${isDesktop ? country?.bg2lg : isTablet ? country?.bg2md : country?.bg2sm})` }}>
                        <div className="container w-full h-full flex items-end justify-between px-5">
                            <div className="flex flex-col lg:w-1/2 w-full justify-end h-full pb-[54px] items-center lg:items-start relative z-50">
                                <motion.h1
                                    initial={{ x: -100, opacity: 0 }}
                                    viewport={{ once: true }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.7 }}
                                    className={`
                                text-white font-extrabold leading-[110%] text-[41px] text-center
                                md:leading-[101%] md:text-[48px] md:mt-8 md:text-left`
                                    }
                                >
                                    {country?.name}
                                </motion.h1>
                                <motion.div initial={{ x: -100, opacity: 0 }} viewport={{ once: true }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.7 }} className="flex gap-3 md:gap-[42px] mt-4 md:mt-8 justify-center md:justify-start">
                                    <Button variant="primary">
                                        <span>Оставить заявку</span>
                                    </Button>
                                    <Link to='/#directions'>
                                        <Button variant="secondary">
                                            <span>Смотреть Направления</span>
                                        </Button>
                                    </Link>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    </div>
}


