import call from '../../assets/home/call.svg';
import click from '../../assets/home/click.svg';
import contract from '../../assets/home/pen.svg';
import money from '../../assets/home/money.svg';
import document from '../../assets/home/document.svg';
import suitcase from '../../assets/home/suitcase.svg';

export const bookingList = [
  {
    text: 'Внимательно вас слушаем',
    icon: call
  },
  {
    text: 'Выбираем Тур ',
    icon: click
  },
  {
    text: 'Подписываем договор  ',
    icon: contract
  },
  {
    text: 'Оплата онлайн или оффлайн',
    icon: money
  },
  {
    text: 'Высылаем документы',
    icon: document
  },
  {
    text: 'Собираем чемодан',
    icon: suitcase
  }
]

export interface Country {
  tags: string[];
  name: string;
  image: string;
  bg: string;
  bg2lg: string;
  bg2md: string;
  bg2sm: string;
  shadow: string;
  layer: string;
  description: string;
  attractions: Attraction[];
  entryConditions: string;
  location: string;
  currency: Currency;
  language: string;
  timeZone: string;
  icon: string;
}

export interface Attraction {
  name: string;
  description: string;
  image: string;
}

export interface Currency {
  code: string;
  name: string;
}