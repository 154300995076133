import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Users, MessageSquare, Home, RefreshCw, LogOut } from 'lucide-react';
import { useData } from '../../utils/context';
import { useAuth } from '../../utils/authcontext';

const Sidebar: React.FC = () => {
  const { refreshData } = useData();
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };
  
  if (!user) return null;

  return (
    <div className="w-64 bg-gray-800 h-screen p-4 flex flex-col">
      <div className="mb-6">
        <h1 className="text-white text-xl font-bold">Admin Panel</h1>
      </div>
      <nav className="flex-grow">
        <ul className="space-y-2">
          <li>
            <Link to="/admin" className="flex items-center text-gray-300 hover:text-white p-2 rounded hover:bg-gray-700">
              <Home size={18} className="mr-2" />
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/admin/employees" className="flex items-center text-gray-300 hover:text-white p-2 rounded hover:bg-gray-700">
              <Users size={18} className="mr-2" />
              Employees
            </Link>
          </li>
          <li>
            <Link to="/admin/reviews" className="flex items-center text-gray-300 hover:text-white p-2 rounded hover:bg-gray-700">
              <MessageSquare size={18} className="mr-2" />
              Reviews
            </Link>
          </li>
        </ul>
      </nav>
      <div className="mt-auto space-y-2">
        <button 
          onClick={() => refreshData()}
          className="flex items-center text-gray-300 hover:text-white p-2 rounded hover:bg-gray-700 w-full"
        >
          <RefreshCw size={18} className="mr-2" />
          Refresh Data
        </button>
        <div className="border-t border-gray-700 my-2"></div>
        <div className="text-gray-400 px-2 text-sm mb-1">
          Logged in as: {user.username}
        </div>
        <button 
          onClick={handleLogout}
          className="flex items-center text-gray-300 hover:text-white p-2 rounded hover:bg-gray-700 w-full"
        >
          <LogOut size={18} className="mr-2" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;