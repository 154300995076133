import React, { useState, useEffect } from 'react';
import { Review } from '../../utils/types';

interface ReviewFormProps {
  review?: Review;
  onSubmit: (review: Omit<Review, 'id'> | Review) => void;
  onCancel: () => void;
}

const ReviewForm: React.FC<ReviewFormProps> = ({ review, onSubmit, onCancel }) => {
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [reviewText, setReviewText] = useState('');

  useEffect(() => {
    if (review) {
      setName(review.name);
      setAvatar(review.avatar);
      setReviewText(review.review);
    }
  }, [review]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (review) {
      onSubmit({ id: review.id, name, avatar, review: reviewText });
    } else {
      onSubmit({ name, avatar, review: reviewText });
    }
    resetForm();
  };

  const resetForm = () => {
    setName('');
    setAvatar('');
    setReviewText('');
  };

  return (
    <div className="bg-white p-6 rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">{review ? 'Edit Review' : 'Add New Review'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Avatar URL</label>
          <input
            type="text"
            value={avatar}
            onChange={(e) => setAvatar(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="/api/placeholder/100/100"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Review</label>
          <textarea
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            className="w-full p-2 border rounded h-32"
            required
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {review ? 'Update' : 'Add'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReviewForm;